import { EuiFlexGroup, EuiFlexItem } from "@equipmentshare/ds2";
import {
  BookletLineIcon,
  Chat3LineIcon,
  FileAddLineIcon,
  QuestionLineIcon,
} from "@equipmentshare/ds2-icons";
import { Device, DeviceInstallStatus } from "@fleet-configuration/client";
import { IconButton, useFlyout } from "@fleet-configuration/components";
import { heaperEvent } from "@fleet-configuration/heaper-utils";
import { LIGHT_BORDER } from "@fleet-configuration/utils";
import { useState } from "react";

import { ContactSupportModal } from "@/components/device-installation-progress/contact-support-modal";
import {
  TriggerSource,
  useInstallHelpWizard,
} from "@/components/device-installation-progress/your-devices-flyout/install-help-wizard";
import { CONSTANTS } from "@/config/constants";
import { AssignDevicesFlyoutProps } from "@/config/constants/flyout";
import { getDeviceInstallationGuideUrl } from "@/config/deviceInstallationGuides";
import { usePendingAssignments } from "@/contexts";
import { DeviceKind } from "@/types";

export type DetailsButtonsProps = {
  device: Device;
};

export const DetailsButtons = ({ device }: DetailsButtonsProps) => {
  const {
    ModalComponent: InstallHelpWizard,
    openModal: openInstallHelpWizard,
  } = useInstallHelpWizard(device, TriggerSource.DEVICE_DETAILS);
  const { openFlyout, setFlyoutProps } = useFlyout<AssignDevicesFlyoutProps>(
    CONSTANTS.FlyoutContextKey.ASSIGN_DEVICES,
  );

  const { pendingAssignments } = usePendingAssignments();
  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);

  const openSupportModal = () => {
    heaperEvent(
      "Fleet Configuration Dashboard - Device Details - Click - Unknown Device?",
      { device_name: device.typeName! },
    );
    setIsSupportModalOpen(true);
  };
  const closeSupportModal = () => setIsSupportModalOpen(false);

  const isAssigned = !!device.assetId || !!pendingAssignments[device.esId];
  const isDetected = device.installStatuses.includes(
    DeviceInstallStatus.Detected,
  );

  const installationGuideUrl = getDeviceInstallationGuideUrl(device.typeName);
  const showInstallHelpButton =
    device.kind === DeviceKind.TRACKER && !isDetected;

  const onAssignClick = () => {
    heaperEvent(
      "Fleet Configuration Dashboard - Device Install Progress - Click - Assign to Asset",
      {
        device_name: device.typeName!,
        source: TriggerSource.DEVICE_DETAILS,
      },
    );

    openFlyout();
    setFlyoutProps({
      device,
      hideOverlay: true,
    });
  };

  return (
    <EuiFlexGroup
      data-testid="details-buttons"
      gutterSize="xs"
      style={{ borderBottom: `${LIGHT_BORDER}`, padding: "12px 12px 6px" }}
    >
      {!!installationGuideUrl && (
        <EuiFlexItem grow={false}>
          <IconButton
            href={installationGuideUrl!}
            iconType={BookletLineIcon}
            isExternal
            label="Install Guide"
            onClick={() => {
              heaperEvent(
                "Fleet Configuration Dashboard - Device Details - Click - View Install Guide",
                {
                  device_name: device.typeName!,
                },
              );
            }}
          />
        </EuiFlexItem>
      )}
      {!isAssigned && (
        <EuiFlexItem grow={false}>
          <IconButton
            iconType={FileAddLineIcon}
            label="Assign to Asset"
            onClick={onAssignClick}
          />
        </EuiFlexItem>
      )}
      {showInstallHelpButton && (
        <EuiFlexItem grow={false}>
          <IconButton
            iconType={Chat3LineIcon}
            label="Install Help"
            onClick={openInstallHelpWizard}
          />
        </EuiFlexItem>
      )}
      <EuiFlexItem grow={false}>
        <IconButton
          iconType={QuestionLineIcon}
          label="Unknown Device?"
          onClick={openSupportModal}
        />
      </EuiFlexItem>
      <ContactSupportModal
        isOpen={isSupportModalOpen}
        onClose={closeSupportModal}
      />
      <InstallHelpWizard />
    </EuiFlexGroup>
  );
};
