import {
  EuiButton,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiText,
} from "@equipmentshare/ds2";
import { heaperEvent } from "@fleet-configuration/heaper-utils";
import {
  EXTERNAL_URLS,
  useParentWindowCommunication,
} from "@fleet-configuration/utils";

import { CONSTANTS } from "@/config/constants";

export type ContactSupportModalProps = {
  isOpen?: boolean;
  onClose: () => void;
};

export const ContactSupportModal = ({
  isOpen = false,
  onClose,
}: ContactSupportModalProps) => {
  const { notifyParentWindow } = useParentWindowCommunication({
    sourceApp: CONSTANTS.APP_NAME,
    parentOrigin: EXTERNAL_URLS.T3,
    type: CONSTANTS.PARENT_WINDOW_MESSAGE_TYPES
      .T3_IFRAME_TRIGGERED_INTERCOM_MESSAGE,
  });

  const handleOnClick = () => {
    notifyParentWindow();
    heaperEvent(
      "Fleet Configuration Dashboard - Contact Support Modal - Click - Contact Support",
      {},
    );
    onClose();
  };

  if (!isOpen) return null;

  return (
    <EuiModal data-testid="contact-support-modal" onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>We&apos;re here to help!</EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiText>
          Please contact support for assistance with this unknown device.
        </EuiText>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButton fill onClick={() => handleOnClick()}>
          Contact Support
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};
