import { heaperEvent } from "@fleet-configuration/heaper-utils";
import {
  EXTERNAL_URLS,
  T3_PATHNAME,
  useParentWindowCommunication,
} from "@fleet-configuration/utils";

import { ActionCard } from "@/components/get-started/action-cards/action-card";
import { GetStartedCardProps } from "@/components/get-started/types";
import { CONSTANTS } from "@/config/constants";

type AddAssetsCardProps = GetStartedCardProps;

// This component routes the user to the Create Asset work flow in the T3 application vs QuickAddAssetsCard form/flyout in fleet-configuration-dashboard
// heapEvent metadata is the same as the one in the QuickAddAssetsCard component
export const AddAssetsCard = ({ state }: AddAssetsCardProps) => {
  const { sendMessageToParentWindow } = useParentWindowCommunication({
    sourceApp: CONSTANTS.APP_NAME,
    parentOrigin: EXTERNAL_URLS.T3,
    type: CONSTANTS.PARENT_WINDOW_MESSAGE_TYPES.T3_IFRAME_TRIGGERED_ROUTE,
  });

  return (
    <>
      <ActionCard
        handleAction={() => {
          heaperEvent(
            "Fleet Configuration Dashboard - Get Started - Click - Configuration Step",
            { step: "Quick Add Assets", source: "getStarted" },
          );
          sendMessageToParentWindow(T3_PATHNAME.CREATE_ASSET);
        }}
        state={state}
        subtitle="Give us the basic information around your assets to quickly get them into T3."
        title="Add Assets"
      />
    </>
  );
};
