"use client";

import {
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiPanel,
  EuiSpacer,
  EuiText,
  useIsWithinBreakpoints,
} from "@equipmentshare/ds2";
import {
  useGetAggregateAssetsByTypeQuery,
  useGetUpstreamIngestionMetadataQuery,
} from "@fleet-configuration/client";
import {
  DataVizBaseDatum,
  useToastContext,
} from "@fleet-configuration/components";
import { heaperEvent } from "@fleet-configuration/heaper-utils";
import {
  EXTERNAL_URLS,
  T3_PATHNAME,
  useParentWindowCommunication,
} from "@fleet-configuration/utils";
import { millify } from "millify";
import { useCallback, useEffect, useState } from "react";

import { PieChartWithLegend } from "@/components/fleet-overview/pie-chart-with-legend";
import {
  pieChartColor,
  pieChartLabel,
} from "@/components/fleet-overview/pie-chart-with-legend/utils";
import { WidgetWrapper } from "@/components/widget-wrapper";
import { CONSTANTS } from "@/config/constants";
import useNotification from "@/hooks/useNotification";
import { GQL } from "@/types";
import { pollWithBackoff } from "@/utils";
import { handleAPIError } from "@/utils/validation";

export const FleetOverview = () => {
  const {
    data: assetData,
    loading: getAggregateAssetsLoading,
    fetchMore: fetchMoreAssets,
    error: getAggregateAssetsError,
  } = useGetAggregateAssetsByTypeQuery();

  /**
   * Initiate a poll with backoff to capture any new assets created or updated in the system
   * that may not be reflected in the current data set.
   */
  useEffect(() => {
    if (fetchMoreAssets) {
      pollWithBackoff(() => fetchMoreAssets({}), { limit: 3, waitMs: 3_000 });
    }
  }, [fetchMoreAssets]);

  useNotification(GQL.NotificationType.AssetCreatedNotification, async () => {
    void pollWithBackoff(() => fetchMoreAssets({}));
  });

  useNotification(GQL.NotificationType.AssetUpdatedNotification, () => {
    void pollWithBackoff(() => fetchMoreAssets({}));
  });

  const { data: upstreamData, loading: getUpstreamIngestionMetadataLoading } =
    useGetUpstreamIngestionMetadataQuery();

  const { showErrorToast } = useToastContext();

  // TODO: Consider moving chart/container sizing to custom hook (shared with DeviceInstallStatus)
  const isXsmall = useIsWithinBreakpoints(["xs"]);
  const isSmall = useIsWithinBreakpoints(["s"]);
  const isMed = useIsWithinBreakpoints(["m"]);

  const CHART_SIZE_MAP = {
    xsmall: "225px",
    small: "250px",
    med: "250px",
    large: "275px",
  };

  const getContainerHeight = useCallback(() => {
    if (isXsmall) return CHART_SIZE_MAP.xsmall;
    if (isSmall) return CHART_SIZE_MAP.small;
    if (isMed) return CHART_SIZE_MAP.med;
    return CHART_SIZE_MAP.large;
  }, [
    CHART_SIZE_MAP.large,
    CHART_SIZE_MAP.med,
    CHART_SIZE_MAP.small,
    CHART_SIZE_MAP.xsmall,
    isMed,
    isSmall,
    isXsmall,
  ]);

  const [containerHeight, setContainerHeight] = useState(getContainerHeight());

  // Set the container height based on the breakpoint
  useEffect(() => {
    const containerHeight = getContainerHeight();
    setContainerHeight(containerHeight);
  }, [getContainerHeight, isXsmall, isSmall, isMed]);

  useEffect(() => {
    if (getAggregateAssetsError) {
      handleAPIError(getAggregateAssetsError, showErrorToast);
    }
    // Excluding 'showToast' from the dependencies array to prevent infinite loop
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAggregateAssetsError]);

  const aggregateAssetTypes = assetData?.aggregations.assetsByType || [];
  const aggregateAssetCount = aggregateAssetTypes.reduce((acc, curr) => {
    return acc + (curr?.count || 0);
  }, 0);
  const hasAssets = aggregateAssetCount > 0;

  const upstreamAssetCount =
    upstreamData?.getUpstreamIngestionMetadata?.assetCount;

  const hasUpstreamAssets = upstreamAssetCount && upstreamAssetCount > 0;
  const assetTypeData: DataVizBaseDatum[] = aggregateAssetTypes.map(
    (assetType) => {
      return {
        id: assetType?.id!,
        color: pieChartColor(parseInt(assetType?.id!)),
        label: pieChartLabel(parseInt(assetType?.id!)),
        value: assetType?.count || 0,
      };
    },
  );

  const { sendMessageToParentWindow } = useParentWindowCommunication({
    sourceApp: CONSTANTS.APP_NAME,
    parentOrigin: EXTERNAL_URLS.T3,
    type: CONSTANTS.PARENT_WINDOW_MESSAGE_TYPES.T3_IFRAME_TRIGGERED_ROUTE,
  });

  const overviewText = hasUpstreamAssets
    ? // display text if assets created via upstream process
      "We got you started by adding the asset details supplied during sign up. As you add and edit your owned assets, you'll see the updates reflected here."
    : // default text if user created assets, i.e. (( upstreamData === null | undefined ) || upstreamAssetCount === 0)
      "As you add and edit your owned assets, you'll see the updates reflected here.";

  return (
    <WidgetWrapper
      subTitle={overviewText}
      subTitleLines={2}
      subTitleLoading={getUpstreamIngestionMetadataLoading}
      title="Fleet Overview"
    >
      <EuiFlexGroup gutterSize="m">
        <EuiFlexItem>
          <EuiPanel color="primary" style={{ minHeight: "96px" }}>
            <EuiFlexGroup
              alignItems="center"
              gutterSize="s"
              responsive={false}
              style={{ height: "100%" }}
            >
              <EuiFlexItem grow={0}>
                {getAggregateAssetsLoading ? (
                  <EuiLoadingSpinner size="m" />
                ) : (
                  <EuiText>
                    <div>
                      <h2 data-testid="number-of-assets">
                        {millify(aggregateAssetCount)}
                      </h2>
                    </div>
                  </EuiText>
                )}
              </EuiFlexItem>

              <EuiFlexItem grow={false}>
                <EuiText size="xs">Assets in T3.</EuiText>
                <EuiButtonEmpty
                  aria-label="Review assets"
                  data-testid="review-assets-button"
                  disabled={!hasAssets || getAggregateAssetsLoading}
                  flush="left"
                  iconSide="right"
                  iconSize="s"
                  iconType={getAggregateAssetsLoading ? "" : "sortRight"}
                  onClick={() =>
                    sendMessageToParentWindow(T3_PATHNAME.COMPANY_ASSETS)
                  }
                  size="xs"
                >
                  <EuiText size="xs">
                    <strong>
                      {getAggregateAssetsLoading
                        ? "Loading assets"
                        : "Review assets"}
                    </strong>
                  </EuiText>
                </EuiButtonEmpty>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiPanel color="subdued">
            <EuiText size="xs">
              <strong>Missing an asset?</strong>
            </EuiText>
            <EuiSpacer size="s" />
            <EuiText color="subdued" size="xs">
              <p>Add it now to get a full fleet overview.</p>
            </EuiText>
            <EuiButtonEmpty
              aria-label="Add asset"
              flush="left"
              iconSide="right"
              iconType="sortRight"
              onClick={() => {
                sendMessageToParentWindow(T3_PATHNAME.CREATE_ASSET);
                heaperEvent(
                  "Fleet Configuration Dashboard - Get Started - Click - Configuration Step",
                  { source: "fleetOverview", step: "Quick Add Assets" },
                );
              }}
              size="xs"
            >
              <strong>Add asset</strong>
            </EuiButtonEmpty>
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="m" />
      <EuiText size="s">
        <h3>Fleet Breakdown</h3>
      </EuiText>
      <EuiSpacer size="xs" />
      <EuiText color="subdued" size="xs">
        <p>A breakdown of your T3 Inventory by Asset Type</p>
      </EuiText>
      <EuiSpacer size="xs" />
      <PieChartWithLegend
        containerDimensions={{
          containerHeight,
          containerWidth: containerHeight,
        }}
        data={assetTypeData}
        isLoading={getAggregateAssetsLoading}
      />
    </WidgetWrapper>
  );
};
