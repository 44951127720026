import { EuiLink, EuiText } from "@equipmentshare/ds2";
import { FlyoutForm, useFlyout } from "@fleet-configuration/components";
import { heaperEvent } from "@fleet-configuration/heaper-utils";
import {
  EXTERNAL_URLS,
  useParentWindowCommunication,
} from "@fleet-configuration/utils";

import { YourDevicesList } from "@/components/device-installation-progress/your-devices-flyout/your-devices-list";
import { CONSTANTS } from "@/config/constants";

export const YourDevicesFlyout = () => {
  const { isFlyoutVisible, closeFlyout } = useFlyout(
    CONSTANTS.FlyoutContextKey.YOUR_DEVICES,
  );

  const { notifyParentWindow } = useParentWindowCommunication({
    sourceApp: CONSTANTS.APP_NAME,
    parentOrigin: EXTERNAL_URLS.T3,
    type: CONSTANTS.PARENT_WINDOW_MESSAGE_TYPES
      .T3_IFRAME_TRIGGERED_INTERCOM_MESSAGE,
  });

  const { closeFlyout: closeFiltersFlyout } = useFlyout(
    CONSTANTS.FlyoutContextKey.FILTERS,
  );

  const { closeFlyout: closeAssignDevicesFlyout } = useFlyout(
    CONSTANTS.FlyoutContextKey.ASSIGN_DEVICES,
  );

  const subHeader = (
    <EuiText>
      <span>
        Search for a device, manage its install status and find helpful
        resources. Missing or unknown devices? {""}
        <EuiLink
          color="primary"
          data-testid="contact-us-link"
          onClick={() => {
            notifyParentWindow();
            heaperEvent(
              "Fleet Configuration Dashboard - Your Devices Flyout - Click - Contact us",
              {},
            );
          }}
        >
          Contact us.
        </EuiLink>
      </span>
    </EuiText>
  );

  return (
    <FlyoutForm
      flyoutBody={<YourDevicesList />}
      handleClose={() => {
        // attempt to close all flyouts that can be opened on top of this flyout.
        // this is a workaround for a bug in EUI where "Esc" key press does not propagate to all flyouts, only the first one.
        closeFlyout();
        closeFiltersFlyout();
        closeAssignDevicesFlyout();
      }}
      headerText="Your Devices"
      isVisible={isFlyoutVisible}
      subHeader={subHeader}
    />
  );
};
