import {
  ApolloError,
  QueryOptions,
  useSearchDevicesQuery,
} from "@fleet-configuration/client";

import { trackerInstallationGuides } from "@/config/TrackerInstallationGuides";
import {
  TrackerInstallationGuide,
  TrackerInstallationGuideMap,
  TrackerInstallationGuideModel,
} from "@/types";

export type TrackerInstallationGuidesResponse = {
  trackerInstallationGuides: TrackerInstallationGuide[];
  loading: boolean;
  error: ApolloError | string | undefined;
};

export type UseGetTrackerInstallationGuidesOptions = {
  fetchPolicy?: QueryOptions["fetchPolicy"];
  pollInterval?: QueryOptions["pollInterval"];
};

const defaultUseGetTrackerOptions: UseGetTrackerInstallationGuidesOptions = {
  fetchPolicy: "cache-first",
};

/**
 * Map of regex patterns to match tracker names to their respective installation guide model.
 */
export const trackerRegexMap: {
  [key in TrackerInstallationGuideModel]?: RegExp;
} = {
  [TrackerInstallationGuideModel.MC4]: /MC-4\+|MC-4\+\+/i,
  [TrackerInstallationGuideModel.FJ2500]: /FJ2500/i,
  [TrackerInstallationGuideModel.GL520]: /GL520|GL500/i,
  [TrackerInstallationGuideModel.MCX101]: /MCX101|MCX 101/i,
  [TrackerInstallationGuideModel.T3_DASH_CAM]: /fleetcam/i,
};

/**
 * Find the tracker installation guide model name by matching the tracker name to a regex pattern.
 */
export const findTrackerGuideByName = (name: string): string | null => {
  name = name.trim();
  for (const [model, regex] of Object.entries(trackerRegexMap)) {
    if (regex && regex.test(name)) {
      return model;
    }
  }
  return null;
};

export const useGetTrackerInstallationGuides = (
  options: UseGetTrackerInstallationGuidesOptions = defaultUseGetTrackerOptions,
) => {
  const { data, loading, error } = useSearchDevicesQuery({
    ...options,
    variables: {
      input: {
        query: "",
      },
    },
  });

  if (error) {
    return {
      trackerInstallationGuides, // Return the default tracker installation guides
      loading,
      error,
    };
  }

  if (loading) {
    return {
      trackerInstallationGuides, // Return the default tracker installation guides
      loading,
      error: undefined,
    };
  }

  const devices = (data?.searchDevices.facets || []).find(
    (facet) => facet.facetId === "DEVICE_TYPE",
  )?.entries;

  if (!devices || !devices.length) {
    return {
      trackerInstallationGuides, // Return the default tracker installation guides
      loading: false,
      error: undefined,
    };
  }

  const enrichTrackerInstallationGuides = (): Map<
    string,
    TrackerInstallationGuideMap
  > => {
    const enrichedTrackerInstallationGuides = new Map(
      trackerInstallationGuides,
    );
    let ownedItemsCount = 0;

    for (let [key, guide] of enrichedTrackerInstallationGuides) {
      const device = devices.find(
        (tracker) => findTrackerGuideByName(tracker!.name) === key,
      );

      if (device && !guide.isOwned) {
        enrichedTrackerInstallationGuides.set(key, { ...guide, isOwned: true });
        ownedItemsCount++;
      }

      /**
       * Since we are only displaying some trackers in the UI, and we only mark
       * a tracker as `isOwned` if it is found in the user's list of trackers we
       * can exit early once all trackers have been marked as owned.
       */
      if (ownedItemsCount === enrichedTrackerInstallationGuides.size) {
        break;
      }
    }

    // Tracker Installation Guides are sorted by isOwned and alphanumerically
    const enrichedTrackerInstallationGuidesArray = [
      ...enrichedTrackerInstallationGuides.entries(),
    ].sort(([keyA, guideA], [keyB, guideB]) => {
      // Sort by isOwned, true first
      if (guideA.isOwned !== guideB.isOwned) {
        return guideB.isOwned ? 1 : -1;
      }
      // If isOwned is the same, sort by key (alphanumerically)
      return keyA.localeCompare(keyB);
    });

    // Ensure sorted items are returned as a Map
    return new Map(enrichedTrackerInstallationGuidesArray);
  };

  const enrichedTrackerInstallationGuides = enrichTrackerInstallationGuides();

  return {
    trackerInstallationGuides: enrichedTrackerInstallationGuides,
    loading,
    error: undefined,
  };
};
