"use client";

import {
  EuiFlexGrid,
  EuiSelect,
  useIsWithinBreakpoints,
} from "@equipmentshare/ds2";
import {
  StepStatus,
  useGetConfigurationStepsQuery,
} from "@fleet-configuration/client";
import { useToastContext } from "@fleet-configuration/components";
import { heaperEvent } from "@fleet-configuration/heaper-utils";
import {
  CSSProperties,
  JSXElementConstructor,
  useEffect,
  useState,
} from "react";

import {
  AddAssetsCard,
  AddUsersCard,
  AssignDevicesCard,
  CreateGroupsCard,
  DrawGeofencesCard,
  InstallDevicesCard,
  UpdateBranchInformationCard,
} from "@/components/get-started/action-cards";
import {
  ConfigurationStep,
  GetStartedCardProps,
  StepStatusFilter,
} from "@/components/get-started/types";
import { WidgetWrapper } from "@/components/widget-wrapper";
import { CONSTANTS } from "@/config/constants";
import useNotification from "@/hooks/useNotification";
import { GQL } from "@/types";
import { handleAPIError } from "@/utils/validation";

type ChecklistStateFilterProps = {
  onChange: (value: StepStatusFilter) => void;
  initialValue?: StepStatusFilter;
};

const ChecklistStateFilter = ({
  onChange,
  initialValue,
}: ChecklistStateFilterProps) => {
  const options = [
    { value: StepStatusFilter.ALL, text: "All" },
    { value: StepStatusFilter.INCOMPLETE, text: "Incomplete" },
    { value: StepStatusFilter.COMPLETE, text: "Complete" },
  ];
  const [value, setValue] = useState(initialValue ?? StepStatusFilter.ALL);

  return (
    <EuiSelect
      aria-label="Filter actions by state"
      compressed
      data-testid="card-state-filter"
      id="card-state-filter"
      onChange={(e) => {
        setValue(e.target.value as StepStatusFilter);
        onChange(e.target.value as StepStatusFilter);
      }}
      options={options}
      value={value}
    />
  );
};

export const GetStarted = () => {
  const {
    data: configurationState,
    error: errorConfigurationSteps,
    fetchMore: getConfigurationSteps,
    loading: loadingConfigurationSteps,
  } = useGetConfigurationStepsQuery({
    fetchPolicy: "network-only",
  });

  useNotification(
    GQL.NotificationType.ConfigurationStepsUpdatedNotification,
    async () => {
      await getConfigurationSteps({});
    },
  );

  const { showErrorToast } = useToastContext();

  useEffect(() => {
    const checklistState = configurationState?.getConfigurationSteps;

    if (!checklistState) {
      return;
    }

    heaperEvent(
      "Fleet Configuration Dashboard - Get Started - Load - Configuration Checklist",
      {
        has_added_assets: checklistState.createAsset === StepStatus.Complete,
        has_added_users: checklistState.createUser === StepStatus.Complete,
        has_installed_devices:
          checklistState.installTracker === StepStatus.Complete,
        has_assigned_devices:
          checklistState.assignHardware === StepStatus.Complete,
        has_updated_branch_info:
          checklistState.updateDefaultBranch === StepStatus.Complete,
        has_drawn_geofences:
          checklistState.createGeofence === StepStatus.Complete,
        has_created_groups: checklistState.createGroup === StepStatus.Complete,
      },
    );
  }, [configurationState?.getConfigurationSteps]);

  useEffect(() => {
    if (errorConfigurationSteps) {
      handleAPIError(errorConfigurationSteps, showErrorToast);
    }
    // Excluding 'showToast' from the dependencies array to prevent infinite loop
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorConfigurationSteps]);

  const [filterValue, setFilterValue] = useState(StepStatusFilter.ALL);

  const actionCardComponentMap: Record<
    ConfigurationStep,
    JSXElementConstructor<GetStartedCardProps>
  > = {
    createUser: AddUsersCard,
    createAsset: AddAssetsCard,
    installTracker: InstallDevicesCard,
    assignHardware: AssignDevicesCard,
    updateDefaultBranch: UpdateBranchInformationCard,
    createGeofence: DrawGeofencesCard,
    createGroup: CreateGroupsCard,
  };

  const filterByState = (actionStatePair: [string, StepStatus]) => {
    const [_, cardState] = actionStatePair;
    if (filterValue === StepStatusFilter.ALL) {
      return true;
    }

    if (filterValue === StepStatusFilter.COMPLETE) {
      return cardState === StepStatus.Complete;
    }

    if (filterValue === StepStatusFilter.INCOMPLETE) {
      return (
        cardState === StepStatus.Incomplete ||
        cardState === StepStatus.Unavailable
      );
    }
  };

  const getActionCards = () => {
    const configurationSteps = configurationState?.getConfigurationSteps;
    const { __typename, ...filteredConfigurationSteps } =
      configurationSteps || {};
    const actionsFilteredByState = Object.entries(
      filteredConfigurationSteps,
    ).filter(filterByState);
    return actionsFilteredByState.map(
      (actionStatePair: [string, StepStatus]) => {
        const [actionName, checklistState] = actionStatePair;
        const CardComponent =
          actionCardComponentMap[actionName as ConfigurationStep];
        return <CardComponent key={actionName} state={checklistState} />;
      },
    );
  };

  const mediaStyles: CSSProperties | undefined = useIsWithinBreakpoints(
    CONSTANTS.LAYOUT.IS_MULTI_COLUMN_LAYOUT,
  )
    ? {
        maxHeight: `${CONSTANTS.LAYOUT.WIDGET_MAX_HEIGHT - 100}px`,
        overflowY: "auto",
      }
    : undefined;

  return (
    <WidgetWrapper
      color="primary"
      extraAction={
        <ChecklistStateFilter
          initialValue={filterValue}
          onChange={setFilterValue}
        />
      }
      subTitle="This is your action plan to get the most out of your fleet."
      title="Get Started"
    >
      <div style={mediaStyles}>
        <EuiFlexGrid gutterSize="s">
          {/* TODO: Handle Error/Loading State */}
          {!loadingConfigurationSteps &&
            !errorConfigurationSteps &&
            getActionCards()}
        </EuiFlexGrid>
      </div>
    </WidgetWrapper>
  );
};
